<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Shared Agreement
        </h1>
      </div>

      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div class="loading mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <ValidationObserver rules="required">
        <div class="px-3">
          <card class="p-5 mt-3">
            <p class="text-lg font-bold">Agreement Details</p>
            <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
              <div class="col-span-6">
                <div class="text-sm text-jet">Employee</div>
                <c-text
                  label=""
                  placeholder="Select Employee"
                  variant="w-full"
                  :disabled="true"
                  v-model="payload.employee"
                />
              </div>
              <div class="col-span-6">
                <div class="text-sm text-jet">Financial Year</div>
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  class="label mt-2"
                  label=""
                  :disabled="true"
                  v-model="payload.year"
                />
              </div>
            </div>

            <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
              <div class="col-span-6">
                <div class="text-sm text-jet">Description</div>
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  class="label mt-2"
                  v-model="payload.description"
                  :disabled="true"
                />
              </div>
              <div class="col-span-6">
                <div class="text-sm text-jet">Template</div>
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  class="label mt-2"
                  v-model="payload.template"
                  :disabled="true"
                />
              </div>
            </div>

            <div class="w-full p-2 h-auto flex flex-col mt-5 cycle">
              <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

              <card class="w-full fcard">
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="doccycle" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #490166">
                      {{ cycleTitle }}
                    </div>
                    <div class="w-full fcard__text">
                      {{ cycleDescription }}
                    </div>
                  </div>
                </div>
              </card>

              <div class="w-full h-auto flex flex-wrap">
                <card class="w-5/12 flex flex-grow mr-3 fcard">
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="calendarIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #e99323">
                        Cycle Period
                      </div>
                      <div class="w-full fcard__text">
                        {{ $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd") }}
                        -
                        {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                      </div>
                    </div>
                  </div>
                </card>

                <card
                  class="w-5/12 flex flex-grow mb-3 fcard"
                  v-for="(cycles, index) in appraisal_cycles"
                  :key="index"
                >
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="reviewIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #2176ff">
                        {{ cycles.name }}
                      </div>
                      <div class="w-full fcard__text">
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_starts),
                            "MMMM dd"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_ends),
                            "MMMM dd"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </card>
        </div>

        <div class="px-3">
          <card class="p-5" style="margin-top: 4px">
            <div
              class="w-full flex flex-row"
              style="margin-top: 20px; height: 44px; flex-grow: 1"
            >
              <div class="w-8/12 h-auto mt-2">
                <h1 class="text-base font-bold">KPAs</h1>
              </div>

              <div class="h-full w-4/12 flex">
                <div class="p-2 w-full flex">
                  <div class="flex w-full justify-end -ml-5 -mt-2">
                    <Badge
                      :label="employeeSubmitted"
                      variant="primary"
                      background-color="rgba(19, 181, 106, 0.08)"
                      color="#13B56A"
                      style="padding: 9px; font-size: 14px; font-weight: 600"
                      v-if="
                        employeeSubmitted === 'submitted' && !approvedSubmit
                      "
                    />

                    <Badge
                      label="Approved"
                      variant="primary"
                      background-color="rgba(19, 181, 106, 0.08)"
                      color="#13B56A"
                      style="
                        padding: 9px;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1.5;
                        margin-top: -1.5%;
                      "
                      v-if="approvedSubmit"
                    />

                    <div
                      class="p-2 mx-3 bg-ghostWhite h-full flex"
                      style="border-radius: 5px"
                    >
                      <span class="text-sm font-black flex flex-grow">
                        Total Allocated Weight:
                      </span>
                      <span class="text-sm text-desire ml-3">
                        {{ allocatedWeight }}%
                      </span>
                      <icon :icon-name="iconInfo" size="ksm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="border-bottom: 1px solid #878e99; height: 1rem" />

            <div
              class="w-full h-auto my-6 flex flex-col"
              style="align-items: center"
              v-if="templateKPATypes.length === 0"
            >
              <div
                class="w-5/12 h-auto mb-4 mt-4 flex"
                style="justify-content: center"
              >
                <icon
                  :icon-name="illustration"
                  size="auto"
                  class-name="icon-Size mb-2"
                />
              </div>
              <div class="w-6/12 h-auto illu-text">
                You set organizational goals and objectives here. KPAs appear
                here when you select a template. You can edit selected template
                based on permissions assigned. Select a template to get started.
              </div>
            </div>

            <div class="flex flex-col w-full my-4" v-else>
              <!----- List of KPA's ---->

              <div class="w-full h-auto flex flex-col">
                <accordion
                  class="mt-6"
                  v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                  :key="kpaNumber"
                  show-divider
                  @open="handleOpen($event, kpaTypes.id)"
                  :is-open="true"
                >
                  <template v-slot:title>
                    <span class="font-bold">{{ kpaTypes.name }}</span>
                  </template>

                  <template v-slot:block>
                    <div
                      class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                    >
                      <span class="font-black text-xs">
                        {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                      </span>
                    </div>
                  </template>

                  <div class="w-full">
                    <ValidationObserver>
                      <scroll-container height="1000px">
                        <Table
                          :headers="KPAHeader"
                          :items="kpaTypes.kpas"
                          class="h-full my-2 table"
                          aria-label="absence table"
                          :has-number="false"
                        >
                          <template v-slot:item="{ item }">
                            <div v-if="item.state" class="-ml-3">
                              <div v-if="item.data.state" class="p-2">
                                <div class="flex flex-col">
                                  <div class="cursor-pointer">
                                    <icon
                                      icon-name="icon-lock"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.name">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter KPI--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-1 w-72"
                                  :disabled="true"
                                  v-model="item.data.name"
                                  v-tooltip.bottom-middle="item.data.name"
                                  label="KPI"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.objective">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Objective--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-72"
                                  :disabled="true"
                                  v-model="item.data.objective"
                                  v-tooltip.bottom-middle="item.data.objective"
                                  label="Objective"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.kpis" class="flex">
                              <div
                                class="label flex"
                                v-for="(n, rating) in item.data.kpis"
                                :key="rating"
                              >
                                <ValidationObserver>
                                  <c-text
                                    variant="w-full"
                                    :class="`mr-2 -mt-2 w-48`"
                                    :placeholder="`Criteria ${rating}`"
                                    :disabled="true"
                                    v-model="item.data.kpis[rating].criteria"
                                    v-tooltip.bottom-middle="
                                      item.data.kpis[rating].criteria
                                    "
                                    :label="`Criteria ${rating} value`"
                                  />
                                </ValidationObserver>
                              </div>
                            </div>

                            <div v-else-if="item.target_date">
                              <div class="label w-72">
                                <div class="date-flex -mt-2 bg-disabled">
                                  <datepicker
                                    placeholder="--Select Day & Month & Year--"
                                    style="width: 100%; outline: none"
                                    input-class="date-input"
                                    format="yyyy-MMMM-dd"
                                    v-model="item.data.target_date"
                                    v-tooltip.bottom-middle="
                                      item.data.target_date
                                    "
                                    :disabled="true"
                                  />
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.weight">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Weight--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-24"
                                  type="number"
                                  min="0"
                                  max="100"
                                  v-model="item.data.weight"
                                  :disabled="true"
                                  :rules="weightRules"
                                  label="Weight"
                                />
                              </div>
                            </div>
                          </template>
                        </Table>
                      </scroll-container>
                    </ValidationObserver>
                  </div>
                </accordion>
              </div>
            </div>
          </card>
        </div>

        <div class="pl-3 flex mt-5">
          <div class="flex flex-grow">
            <Button class="bg-dynamicBackBtn text-white" @click="setLocalStorage()">
              <span class="mr-6 ml-6">Adopt</span>
            </Button>

            <Button
              background-color="none"
              class="ml-4"
              style="color: #321c3b"
              @click="$router.back()"
            >
              Close
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from '@/utilities/timeFormatMixin';

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Icon,
    CText,
    Badge,
    Card,
    Button,
    Accordion,
    Table,
    ScrollContainer,
    Datepicker,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "Agreement",
        },
        {
          disabled: false,
          text: "Shared Agreement",
          href: "Shared Agreement",
          id: "Shared Agreement",
        },
      ],
      illustration: "illustration",
      allocatedWeight: 0,
      templateKPATypes: [],
      agreementKpaTypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      inputRules: [
        "required",
        {
          name: "data",
          rule: (w) => this.validateEntries(w),
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      loadingBar: true,
      loadingData: true,
      loadingEmp: true,
      loading: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      watchOthers: false,
      addOthersModal: false,
      previousGoalModal: false,
      userReportingTo: null,
      Ratings: [],
      ratingsIds: [],
      sharedId: "",
      templateOptions: [],
      goalsKPA: [],
      tenplateIds: [],
      validateKPA: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      addEmployeesModal: false,
      selectedEmployees: [],
      viewEmployee: [],
      viewEmployeesModal: false,
      appraisal_cycles: [],
      employees: [],
      sharedEmployees: [],
      managerLock: false,
      warningEmployees: false,
      kpaData: [],
      initialTemplate: "",
      kpaDetails: [],
      kpaWeights: [],
      agreementKPA: [],
      approvedSubmit: false,
      employeeSubmitted: "",
      kpaName: [],
      agreement_kpa: [],
      totalWeight: "",
      validateKpa: "",
      reportingTo: "",
      createAgreement: false,
      manager: false,
      payload: {
        employee: "",
        year: "",
        description: "",
        template: "",
        templateId: "",
        title: "",
        kpa_types: [],
      },
    };
  },

  methods: {
    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum > 100) {
        return "Total allocated weight for all KPA's cannot be greater than 100%";
      }

      this.allocatedWeight = sum;

      return true;
    },

    getEmployeesAgreement() {
      this.$_getEmployeeAgreement(this.$route.params.user).then((response) => {
        this.loading = false;
        const myAgreements = response.data.EmployeePerformanceAgreements;
        const employeeData = response.data.employee;

        myAgreements.filter((aggreement) => {
          if (aggreement.id === this.$route.params.id) {
            this.payload.employee = `${employeeData.fname} ${employeeData.lname}`;
            this.payload.year = aggreement.year;
            this.payload.description = aggreement.description;
            this.payload.template = aggreement.performance_template.title;
            this.payload.templateId = aggreement.templateId;
            this.approvedSubmit = aggreement.approveAndSubmit;
            this.employeeSubmitted = aggreement.agreement_status;
            const agreementKPA = aggreement.employee_agreement_kpa;
            this.agreement_kpa = aggreement.employee_agreement_kpa;

            this.$_getOneTemplate(aggreement.templateId).then((result) => {
              this.$_getKPAWeights(aggreement.templateId).then((res) => {
                const kpaData = res.data.data;

                this.kpaWeights = kpaData;

                let sum = 0;

                this.kpaWeights.forEach((kpa) => {
                  sum += kpa.totalKpiWeight;
                });

                this.totalWeight = sum;
              });

              this.cycleDescription =
                result.data.PerformanceTemplate.agreement_title;
              this.cycleTitle =
                result.data.PerformanceTemplate.agreement_description;

              this.templateKPATypes = result.data.PerformanceTemplate.kpa_types;

              this.templateKPATypes.forEach((kpaType) => {
                // eslint-disable-next-line no-param-reassign
                kpaType.kpas = [];

                agreementKPA.filter((employeeKpi) => {
                  if (employeeKpi.kpaTypeId === kpaType.id) {
                    kpaType.kpas.push({
                      state: true,
                      isLocked: employeeKpi.isLocked,
                      name: employeeKpi.name,
                      objective: employeeKpi.objective,
                      kpaId: employeeKpi.id,
                      kpis: this.getCriteriaData(employeeKpi.kpis_employee),
                      target_date: this.formatEmployeeDate(
                        employeeKpi.targetDate
                      ),
                      weight: employeeKpi.weight,
                    });
                  }
                  return {};
                });
              });
            });
          }
          return {};
        });
      });
    },

    setLocalStorage() {
      const agreementArr = {
        year: this.payload.year,
        templateId: this.payload.templateId,
        employee_agreement_kpa: this.agreement_kpa,
      };

      window.localStorage.setItem(
        "adoptAgreement",
        JSON.stringify(agreementArr)
      );

      this.$toasted.success('Agreement adopted successfully', {duration: 5000});

      this.$router.back();
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis.sort((a, b) => a.score.rating - b.score.rating);

      this.Ratings = sortedRatings;
      // eslint-disable-next-line no-param-reassign
      kpis = sortedKPIS;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
          return {};
        });
      } else {
        this.Ratings.map((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: "",
          });
          return {};
        });
      }

      return criteriaData;
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.forEach((appraisalCycles) => {
          const startDate = `${
            appraisalCycles.cycle_start_date
          }`;

          const endDate = `${
            appraisalCycles.cycle_end_date
          }`;


          this.cycleStartPeriod = this.setDates(startDate).toISOString();

          this.cycleEndPeriod = this.setDates(endDate).toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = `${
              cycle.appraisal_starts
            }`;

            const endCycleDate = `${
              cycle.appraisal_ends
            }`;


            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: this.setDates(startCycleDate).toISOString(),
              appraisal_ends: this.setDates(endCycleDate).toISOString(),
            });
          });
        });
      });
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },
  },

  mounted() {
    this.getEmployeesAgreement();
    this.getAppraisalCycles();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: none;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>